import React, { useRef } from 'react'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

import useOnMouseEnter from 'hooks/useOnMouseEnter'
import useTimeline from 'hooks/useTimeline'

import { Text, RichText } from 'components/typo'
import classnames from 'classnames/bind'
import { ReactComponent as Icon } from './tooltip.svg'

import css from './styles.module.scss'

const cx = classnames.bind(css)
const bayBlue = '#1d1b84'

const Tooltip = ({ content, className, children, position = 'right', marketplaceContentClasses = null }) => {
  const [mouseIsHover, onMouseEnter, onMouseLeave] = useOnMouseEnter(false)
  const contentRef = useRef()
  const iconRef = useRef()
  const wrapperRef = useRef()

  const tl = useTimeline(
    {
      paused: true,
    },
    (tl) => {
      tl.to(
        iconRef.current.firstChild,
        {
          fill: bayBlue,
          opacity: 1,
          duration: 0.2,
          ease: 'none',
        },
        '<'
      )
      tl.fromTo(
        wrapperRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          duration: 0.15,
          ease: 'none',
        },
        '<'
      ).fromTo(
        contentRef.current,
        {
          z: -150,
        },
        {
          z: 0,
          transformPerspective: 1000,
          duration: 0.6,
          force3D: true,
          ease: 'elastic.out(1, 0.75)',
        },
        '<'
      )
    },
    []
  )

  useIsomorphicLayoutEffect(() => {
    if (mouseIsHover) {
      tl.timeScale(1)
      tl.play()
    } else {
      tl.timeScale(2)
      tl.reverse()
    }
  }, [mouseIsHover])

  return (
    <div
      className={cx(css.Tooltip, className)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children ? (
        <div ref={iconRef}>{children}</div>
      ) : (
        <Icon ref={iconRef} className={css.tooltipIcon} />
      )}

      <div ref={wrapperRef} className={cx(css.contentContainer, position)}>
        <div className={css.contentWrapper}>
          <Text
            tag="div"
            theme="white"
            size="smaller"
            forwardRef={contentRef}
            className={cx(css.content, marketplaceContentClasses)}
          >
            <RichText
              forwardRef={contentRef}
              text={content}
              theme="white"
              size="smaller"
              markdownProps={{
                disallowedTypes: ['paragraph'],
                unwrapDisallowed: true,
              }}
            />
          </Text>
        </div>
      </div>
    </div>
  )
}

export default Tooltip
