import Img from 'next/image'
import ResultsList from './ResultsList'
import MissingNpmPackageBanner from '../../MissingNpmPackageBanner'
import notFoundIcon from 'public/assets/marketplace_search-not-found-icon.svg'

import css from './styles.module.scss'

const EmptyState = ({ children }) => (
  <div className={css.marketplaceHomePage__NotFoundContainer}>
    <div className={css.marketplaceHomePage__NotFoundMessage}>{children}</div>
    <Img src={notFoundIcon} width={45} height={56} />
  </div>
)

const SearchResults = ({ query, results, status }) => {
  const { plugins, providers } = results

  if (status === 'idle') {
    // The user hasn't started typing
    return <EmptyState>Search Strapi Market...</EmptyState>
  }

  if (status === 'loading') {
    // We don't have the data yet
    return (
      <EmptyState>
        Loading results for <span>&quot;{query}&quot;...</span>
      </EmptyState>
    )
  }

  if (status === 'error') {
    // There was an error fetching the data
    return (
      <EmptyState>
        Error loading results for <span>&quot;{query}&quot;</span>
      </EmptyState>
    )
  }

  // We have the data, return results accordingly
  if (plugins.length && providers.length) {
    // Results contain both plugin and providers
    return (
      <>
        <ResultsList npmPackages={plugins} npmPackageType="plugin" />
        <ResultsList npmPackages={providers} npmPackageType="provider" />
      </>
    )
  }

  if (plugins.length) {
    // Results contain just plugins
    return <ResultsList npmPackages={plugins} npmPackageType="plugin" />
  }

  if (providers.length) {
    // Results contain just providers
    return <ResultsList npmPackages={providers} npmPackageType="provider" />
  }

  // There are no results
  return (
    <div className={css.marketplaceResults__none}>
      <EmptyState>
        No results for <span>&quot;{query}&quot;</span>
      </EmptyState>
      <MissingNpmPackageBanner />
    </div>
  )
}

export default SearchResults
