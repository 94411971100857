export const actions = {
  LOAD_RESULTS: 'LOAD_RESULTS',
  SET_RESULTS: 'SET_RESULTS',
  ERROR: 'ERROR',
  RESET: 'RESET',
}

export const initialState = {
  results: {
    plugins: [],
    providers: [],
  },
  status: 'idle',
}

export function reducer(state, action) {
  switch (action.type) {
    case actions.LOAD_RESULTS:
      return {
        ...state,
        status: 'loading',
      }
    case actions.SET_RESULTS:
      return {
        ...state,
        results: action.payload,
        status: 'success',
      }
    case actions.ERROR:
      return {
        ...state,
        status: 'error',
      }
    case 'RESET':
      return initialState
    default:
      return state
  }
}
