import css from './styles.module.scss'
import Button from 'src/components/shared/Button'

const MissingNpmPackageBanner = () => {
  return (
    <div className={css.missingNpmPackageBanner}>
      <div>
        <h3 className={css.title}>👀 Missing a plugin or provider?</h3>
        <p className={css.description}>
          Tell us what plugin or provider you are looking for and we&apos;ll let
          our community of developers know in case they are in search of
          inspiration!
        </p>
      </div>
      <Button
        id="marketplace-missing-plugin"
        link={{
          href: 'https://strapi.canny.io/plugin-requests',
          target: '_blank',
        }}
        theme="marketplaceBlue"
      >
        Tell us what you need
      </Button>
    </div>
  )
}

export default MissingNpmPackageBanner
