import PropTypes from 'prop-types'
import Link from 'next/link'

import simplePluralize from '../../utils/simple-pluralize'
import MarketplaceCard from '../../MarketplaceCard'

import css from './styles.module.scss'

const ResultsList = ({ npmPackages, npmPackageType }) => {
  const pluralPackageType = simplePluralize(npmPackageType)

  return (
    <div className={css.marketplaceResultsList}>
      <h3 className={css.marketplaceResultsList__sectionTitle}>
        {pluralPackageType}
      </h3>
      {npmPackages.map((npmPackage) => {
        return (
          <Link
            key={npmPackage.id}
            href={`/marketplace/${pluralPackageType}/${npmPackage.attributes.slug}`}
            as={`/${pluralPackageType}/${npmPackage.attributes.slug}`}
          >
            <a style={{ textDecoration: 'none' }}>
              <MarketplaceCard
                npmPackage={npmPackage}
                npmPackageType={npmPackageType}
                isSearchResult={true}
              />
            </a>
          </Link>
        )
      })}
    </div>
  )
}

ResultsList.propTypes = {
  npmPackages: PropTypes.array.isRequired,
  npmPackageType: PropTypes.string.isRequired
}

export default ResultsList
