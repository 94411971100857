import PropTypes from 'prop-types'
import Input from 'components/shared/Input'
import Img from 'next/image'
import searchIcon from 'public/assets/marketplace_search-icon.svg'

import cx from 'classnames'
import css from './styles.module.scss'

const SearchInput = ({
  searchQuery,
  setSearchQuery,
  isModalOpen,
  openModal,
  closeModal,
  isModalInput = false,
}) => {
  const handleOpenModal = () => {
    if (!isModalOpen) {
      openModal()
    }
  }

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value)
    handleOpenModal()
  }

  const modalClass = isModalInput ? css.modal : ''

  return (
    <div className={cx(css.marketplaceInputContainer, modalClass)}>
      <div className={cx(css.searchIcon, modalClass)}>
        <Img src={searchIcon} width={10} height={10} alt="Search icon" />
      </div>
      <Input
        autoFocus={isModalInput}
        className={cx(css.marketplaceSearchInput, modalClass)}
        placeholder="Search"
        value={isModalInput ? searchQuery : ''}
        onClick={handleOpenModal}
        onChange={handleInputChange}
      />
      {isModalInput ? (
        <button
          onClick={closeModal}
          className={cx(css.closeButton, modalClass)}
        >
          cancel
        </button>
      ) : null}
    </div>
  )
}

SearchInput.propTypes = {
  searchQuery: PropTypes.string.isRequired,
  setSearchQuery: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  isModalInput: PropTypes.bool,
}

export default SearchInput
