import React from 'react'

import classnames from 'classnames/bind'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const Input = ({ className, invalid, ...rest }) => {
  return <input className={cx(css.Input, className, { invalid })} {...rest} />
}

Input.defaultProps = {}

export default Input
