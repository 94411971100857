import { useState, useRef, useEffect, useReducer } from 'react'
import { createPortal } from 'react-dom'
import { useClickAway, useKeyPressEvent } from 'react-use'
import SearchInput from './SearchInput'
import SearchResults from './SearchResults'
import { reducer, initialState, actions } from './reducer'
import getPackages from 'src/marketplace/services/get-packages'
import useDebounce from 'src/hooks/useDebounce'
import css from './styles.module.scss'

const MarketplaceSearch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchState, dispatch] = useReducer(reducer, initialState)
  const [searchQuery, setSearchQuery] = useState('')
  const debouncedSearchQuery = useDebounce(searchQuery, 500)
  const modalRef = useRef(null)

  const openModal = async () => {
    setIsModalOpen(true)
    document.body.style.overflow = 'hidden'
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSearchQuery('')
    dispatch({ type: actions.RESET })
    document.body.style.overflow = 'unset'
  }

  useEffect(() => {
    const search = async () => {
      dispatch({ type: actions.LOAD_RESULTS })
      const npmPackages = await getPackages({
        search: debouncedSearchQuery,
        pagination: { page: 1, pageSize: 10 },
        sort: 'npmDownloads:desc',
      })
      dispatch({ type: actions.SET_RESULTS, payload: npmPackages })
    }
    if (debouncedSearchQuery.length > 0) {
      try {
        search()
      } catch (error) {
        dispatch({ type: actions.ERROR })
      }
    } else {
      dispatch({ type: actions.RESET })
    }
  }, [debouncedSearchQuery, dispatch])

  useClickAway(modalRef, closeModal)
  useKeyPressEvent('Escape', closeModal)

  return (
    <div className={css.marketplaceSearch}>
      <SearchInput
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        isModalOpen={isModalOpen}
        openModal={openModal}
      />

      {isModalOpen
        ? createPortal(
            <div className={css.marketplaceSearch__overlay}>
              <div ref={modalRef} className={css.marketplaceSearch__modal}>
                <SearchInput
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  isModalInput={true}
                />
                <SearchResults
                  query={debouncedSearchQuery}
                  results={searchState.results}
                  status={searchState.status}
                />
              </div>
            </div>,
            document.body
          )
        : null}
    </div>
  )
}

export default MarketplaceSearch
