import PropTypes from 'prop-types'
import classnames from 'classnames/bind'
import Link from 'src/components/shared/Link'
import Layout from 'src/components/shared/Layout'
import MarketplaceSearch from '../MarketplaceSearch'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const MarketplaceNavigation = ({ breadcrumbs }) => {
  return (
    <div className={css.marketplaceNavigationWrapper}>
      <Layout type="wrapper">
        <div className={css.marketplaceNavigation}>
          <nav className={css.marketplaceNavigation__breadcrumbs}>
            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <Link
                  key={index}
                  href={{
                    pathname: breadcrumb.link.href,
                    query: breadcrumb.link.query || {},
                  }}
                  as={{
                    pathname: breadcrumb.link.as,
                    query: breadcrumb.link.query || {},
                  }}
                  className={cx(
                    css.marketplaceNavigation__breadcrumbs__item,
                    breadcrumb.isActive &&
                      css.marketplaceNavigation__breadcrumbs__item_active
                  )}
                >
                  {breadcrumb.name}
                </Link>
              )
            })}
          </nav>
          <div className={css.marketplaceNavigation__search}>
            <MarketplaceSearch />
          </div>
        </div>
      </Layout>
    </div>
  )
}

MarketplaceNavigation.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      isActive: PropTypes.bool.isRequired,
      link: PropTypes.shape({
        href: PropTypes.string.isRequired,
        as: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
}

export default MarketplaceNavigation
