import qs from 'qs'

// Prepare to fetch plugins
const getPackages = async (endpoint, params) => {
  const queryString = params
    ? `?${qs.stringify(params, { encode: false })}`
    : ''
  const result = await fetch(
    `${process.env.NEXT_PUBLIC_MARKETPLACE_API_URL}/${endpoint}${queryString}`
  )
  const { data } = await result.json()
  return data
}

export default async (params) => {
  // Fetch plugins and providers in parallel
  const [plugins, providers] = await Promise.all([
    getPackages('plugins', params),
    getPackages('providers', params),
  ])

  return { plugins, providers }
}
